/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type ActivityLogCreateManyUserInput = {
  action: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityLogCreateManyUserInputEnvelope = {
  data: Array<ActivityLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ActivityLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityLogCreateManyUserInputEnvelope>;
};

export type ActivityLogCreateOrConnectWithoutUserInput = {
  create: ActivityLogCreateWithoutUserInput;
  where: ActivityLogWhereUniqueInput;
};

export type ActivityLogCreateWithoutUserInput = {
  action: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityLogListRelationFilter = {
  every?: InputMaybe<ActivityLogWhereInput>;
  none?: InputMaybe<ActivityLogWhereInput>;
  some?: InputMaybe<ActivityLogWhereInput>;
};

export type ActivityLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityLogWhereInput = {
  AND?: InputMaybe<Array<ActivityLogWhereInput>>;
  NOT?: InputMaybe<Array<ActivityLogWhereInput>>;
  OR?: InputMaybe<Array<ActivityLogWhereInput>>;
  action?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type ActivityLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BusinessUnitCreateManyFranchiseeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessUnitCreateManyFranchiseeInputEnvelope = {
  data: Array<BusinessUnitCreateManyFranchiseeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessUnitCreateNestedManyWithoutFranchiseeInput = {
  connect?: InputMaybe<Array<BusinessUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessUnitCreateOrConnectWithoutFranchiseeInput>>;
  create?: InputMaybe<Array<BusinessUnitCreateWithoutFranchiseeInput>>;
  createMany?: InputMaybe<BusinessUnitCreateManyFranchiseeInputEnvelope>;
};

export type BusinessUnitCreateNestedManyWithoutSuppliersInput = {
  connect?: InputMaybe<Array<BusinessUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessUnitCreateOrConnectWithoutSuppliersInput>>;
  create?: InputMaybe<Array<BusinessUnitCreateWithoutSuppliersInput>>;
};

export type BusinessUnitCreateNestedOneWithoutInventoryItemCategoryInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutInventoryItemCategoryInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutInventoryItemCategoryInput>;
};

export type BusinessUnitCreateNestedOneWithoutInventoryItemsInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutInventoryItemsInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutInventoryItemsInput>;
};

export type BusinessUnitCreateNestedOneWithoutMenusInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutMenusInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutMenusInput>;
};

export type BusinessUnitCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutProductsInput>;
};

export type BusinessUnitCreateNestedOneWithoutPurchasesInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutPurchasesInput>;
};

export type BusinessUnitCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutUsersInput>;
};

export type BusinessUnitCreateOrConnectWithoutFranchiseeInput = {
  create: BusinessUnitCreateWithoutFranchiseeInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutInventoryItemCategoryInput = {
  create: BusinessUnitCreateWithoutInventoryItemCategoryInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutInventoryItemsInput = {
  create: BusinessUnitCreateWithoutInventoryItemsInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutMenusInput = {
  create: BusinessUnitCreateWithoutMenusInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutProductsInput = {
  create: BusinessUnitCreateWithoutProductsInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutPurchasesInput = {
  create: BusinessUnitCreateWithoutPurchasesInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutSuppliersInput = {
  create: BusinessUnitCreateWithoutSuppliersInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutUsersInput = {
  create: BusinessUnitCreateWithoutUsersInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateWithoutFranchiseeInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutInventoryItemCategoryInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutInventoryItemsInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutMenusInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutProductsInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutPurchasesInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutSuppliersInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutUsersInput = {
  cashFlows?: InputMaybe<CashFlowCreateNestedManyWithoutBusinessUnitInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBusinessUnitInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutBusinessUnitInput>;
  slug: Scalars['String'];
  suppliers?: InputMaybe<SupplierCreateNestedManyWithoutBusinessUnitsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessUnitListRelationFilter = {
  every?: InputMaybe<BusinessUnitWhereInput>;
  none?: InputMaybe<BusinessUnitWhereInput>;
  some?: InputMaybe<BusinessUnitWhereInput>;
};

export type BusinessUnitOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BusinessUnitOrderByWithRelationInput = {
  cashFlows?: InputMaybe<CashFlowOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  franchisee?: InputMaybe<FranchiseeOrderByWithRelationInput>;
  franchiseeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryOrderByRelationAggregateInput>;
  inventoryItems?: InputMaybe<InventoryItemOrderByRelationAggregateInput>;
  menus?: InputMaybe<MenuOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  purchases?: InputMaybe<PurchaseOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  suppliers?: InputMaybe<SupplierOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type BusinessUnitRelationFilter = {
  is?: InputMaybe<BusinessUnitWhereInput>;
  isNot?: InputMaybe<BusinessUnitWhereInput>;
};

export type BusinessUnitWhereInput = {
  AND?: InputMaybe<Array<BusinessUnitWhereInput>>;
  NOT?: InputMaybe<Array<BusinessUnitWhereInput>>;
  OR?: InputMaybe<Array<BusinessUnitWhereInput>>;
  cashFlows?: InputMaybe<CashFlowListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  franchisee?: InputMaybe<FranchiseeRelationFilter>;
  franchiseeId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItemCategory?: InputMaybe<InventoryItemCategoryListRelationFilter>;
  inventoryItems?: InputMaybe<InventoryItemListRelationFilter>;
  menus?: InputMaybe<MenuListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  purchases?: InputMaybe<PurchaseListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  suppliers?: InputMaybe<SupplierListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type BusinessUnitWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CashFlowCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  expenses: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  income: Scalars['Float'];
  netCashFlow: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CashFlowCreateManyBusinessUnitInputEnvelope = {
  data: Array<CashFlowCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CashFlowCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<CashFlowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CashFlowCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<CashFlowCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<CashFlowCreateManyBusinessUnitInputEnvelope>;
};

export type CashFlowCreateOrConnectWithoutBusinessUnitInput = {
  create: CashFlowCreateWithoutBusinessUnitInput;
  where: CashFlowWhereUniqueInput;
};

export type CashFlowCreateWithoutBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  expenses: Scalars['Float'];
  income: Scalars['Float'];
  netCashFlow: Scalars['Float'];
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutCashFlowInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CashFlowListRelationFilter = {
  every?: InputMaybe<CashFlowWhereInput>;
  none?: InputMaybe<CashFlowWhereInput>;
  some?: InputMaybe<CashFlowWhereInput>;
};

export type CashFlowOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CashFlowRelationFilter = {
  is?: InputMaybe<CashFlowWhereInput>;
  isNot?: InputMaybe<CashFlowWhereInput>;
};

export type CashFlowWhereInput = {
  AND?: InputMaybe<Array<CashFlowWhereInput>>;
  NOT?: InputMaybe<Array<CashFlowWhereInput>>;
  OR?: InputMaybe<Array<CashFlowWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  expenses?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  income?: InputMaybe<FloatFilter>;
  netCashFlow?: InputMaybe<FloatFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CashFlowWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CreateInventoryItemCategoryInput = {
  businessUnitId?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type CreateInventoryItemDto = {
  businessUnitId: Scalars['Int'];
  categoryId: Scalars['Int'];
  itemType: ItemType;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  packageQuantity: Scalars['Float'];
  positionOnCountInventory: Scalars['Float'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplierId?: InputMaybe<Scalars['Int']>;
  unitOfMeasureForPackageId: Scalars['Int'];
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
};

export type CreateMenuItemDto = {
  businessUnitId: Scalars['Float'];
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateProductDto = {
  businessUnitId: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type CreateProductSaleDto = {
  inventoryItemId?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  saleDate?: InputMaybe<Scalars['DateTime']>;
  unitOfMeasureId?: InputMaybe<Scalars['Int']>;
};

export type CreatePurchaseDto = {
  businessUnitId: Scalars['Int'];
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  supplierId: Scalars['Int'];
  totalPrice: Scalars['Float'];
};

export type CreateRecipeItemDto = {
  inventoryItemId: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type CreateSupplierDto = {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateUnitOfMeasureDto = {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
};

export type CreateWasteDto = {
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumItemTypeFilter = {
  equals?: InputMaybe<ItemType>;
  in?: InputMaybe<Array<ItemType>>;
  not?: InputMaybe<NestedEnumItemTypeFilter>;
  notIn?: InputMaybe<Array<ItemType>>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumSectorFilter = {
  equals?: InputMaybe<Sector>;
  in?: InputMaybe<Array<Sector>>;
  not?: InputMaybe<NestedEnumSectorFilter>;
  notIn?: InputMaybe<Array<Sector>>;
};

export type EnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FranchiseeCreateManyFranchisorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchiseeCreateManyFranchisorInputEnvelope = {
  data: Array<FranchiseeCreateManyFranchisorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FranchiseeCreateNestedManyWithoutFranchisorInput = {
  connect?: InputMaybe<Array<FranchiseeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FranchiseeCreateOrConnectWithoutFranchisorInput>>;
  create?: InputMaybe<Array<FranchiseeCreateWithoutFranchisorInput>>;
  createMany?: InputMaybe<FranchiseeCreateManyFranchisorInputEnvelope>;
};

export type FranchiseeCreateNestedOneWithoutBusinessUnitsInput = {
  connect?: InputMaybe<FranchiseeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchiseeCreateOrConnectWithoutBusinessUnitsInput>;
  create?: InputMaybe<FranchiseeCreateWithoutBusinessUnitsInput>;
};

export type FranchiseeCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<FranchiseeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchiseeCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<FranchiseeCreateWithoutUsersInput>;
};

export type FranchiseeCreateOrConnectWithoutBusinessUnitsInput = {
  create: FranchiseeCreateWithoutBusinessUnitsInput;
  where: FranchiseeWhereUniqueInput;
};

export type FranchiseeCreateOrConnectWithoutFranchisorInput = {
  create: FranchiseeCreateWithoutFranchisorInput;
  where: FranchiseeWhereUniqueInput;
};

export type FranchiseeCreateOrConnectWithoutUsersInput = {
  create: FranchiseeCreateWithoutUsersInput;
  where: FranchiseeWhereUniqueInput;
};

export type FranchiseeCreateWithoutBusinessUnitsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisor: FranchisorCreateNestedOneWithoutFranchiseesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutFranchiseeInput>;
};

export type FranchiseeCreateWithoutFranchisorInput = {
  businessUnits?: InputMaybe<BusinessUnitCreateNestedManyWithoutFranchiseeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutFranchiseeInput>;
};

export type FranchiseeCreateWithoutUsersInput = {
  businessUnits?: InputMaybe<BusinessUnitCreateNestedManyWithoutFranchiseeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisor: FranchisorCreateNestedOneWithoutFranchiseesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchiseeListRelationFilter = {
  every?: InputMaybe<FranchiseeWhereInput>;
  none?: InputMaybe<FranchiseeWhereInput>;
  some?: InputMaybe<FranchiseeWhereInput>;
};

export type FranchiseeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FranchiseeOrderByWithRelationInput = {
  businessUnits?: InputMaybe<BusinessUnitOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  franchisor?: InputMaybe<FranchisorOrderByWithRelationInput>;
  franchisorId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type FranchiseeRelationFilter = {
  is?: InputMaybe<FranchiseeWhereInput>;
  isNot?: InputMaybe<FranchiseeWhereInput>;
};

export type FranchiseeWhereInput = {
  AND?: InputMaybe<Array<FranchiseeWhereInput>>;
  NOT?: InputMaybe<Array<FranchiseeWhereInput>>;
  OR?: InputMaybe<Array<FranchiseeWhereInput>>;
  businessUnits?: InputMaybe<BusinessUnitListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  franchisor?: InputMaybe<FranchisorRelationFilter>;
  franchisorId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type FranchiseeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Franchisor = {
  __typename?: 'Franchisor';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FranchisorCreateNestedOneWithoutConsultantsInput = {
  connect?: InputMaybe<FranchisorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchisorCreateOrConnectWithoutConsultantsInput>;
  create?: InputMaybe<FranchisorCreateWithoutConsultantsInput>;
};

export type FranchisorCreateNestedOneWithoutFranchiseesInput = {
  connect?: InputMaybe<FranchisorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchisorCreateOrConnectWithoutFranchiseesInput>;
  create?: InputMaybe<FranchisorCreateWithoutFranchiseesInput>;
};

export type FranchisorCreateOrConnectWithoutConsultantsInput = {
  create: FranchisorCreateWithoutConsultantsInput;
  where: FranchisorWhereUniqueInput;
};

export type FranchisorCreateOrConnectWithoutFranchiseesInput = {
  create: FranchisorCreateWithoutFranchiseesInput;
  where: FranchisorWhereUniqueInput;
};

export type FranchisorCreateWithoutConsultantsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisees?: InputMaybe<FranchiseeCreateNestedManyWithoutFranchisorInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchisorCreateWithoutFranchiseesInput = {
  consultants?: InputMaybe<UserCreateNestedManyWithoutFranchisorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchisorOrderByWithRelationInput = {
  consultants?: InputMaybe<UserOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  franchisees?: InputMaybe<FranchiseeOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FranchisorRelationFilter = {
  is?: InputMaybe<FranchisorWhereInput>;
  isNot?: InputMaybe<FranchisorWhereInput>;
};

export type FranchisorWhereInput = {
  AND?: InputMaybe<Array<FranchisorWhereInput>>;
  NOT?: InputMaybe<Array<FranchisorWhereInput>>;
  OR?: InputMaybe<Array<FranchisorWhereInput>>;
  consultants?: InputMaybe<UserListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  franchisees?: InputMaybe<FranchiseeListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FranchisorWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InventoryAdjustmentDto = {
  countedQuantity: Scalars['Float'];
  itemId: Scalars['Int'];
  observation: Scalars['String'];
  userId: Scalars['Int'];
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  businessUnitId: Scalars['Int'];
  category?: Maybe<InventoryItemCategory>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryTransactions: Array<InventoryTransaction>;
  itemType: ItemType;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  packageQuantity?: Maybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['Int']>;
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureForPackage?: Maybe<UnitOfMeasure>;
  unitOfMeasureForPackageId?: Maybe<Scalars['Int']>;
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type InventoryItemCategory = {
  __typename?: 'InventoryItemCategory';
  businessUnitId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItems: Array<InventoryItem>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InventoryItemCategoryCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCategoryCreateManyBusinessUnitInputEnvelope = {
  data: Array<InventoryItemCategoryCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCategoryCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<InventoryItemCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCategoryCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<InventoryItemCategoryCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<InventoryItemCategoryCreateManyBusinessUnitInputEnvelope>;
};

export type InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput = {
  connect?: InputMaybe<InventoryItemCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InventoryItemCategoryCreateOrConnectWithoutInventoryItemsInput>;
  create?: InputMaybe<InventoryItemCategoryCreateWithoutInventoryItemsInput>;
};

export type InventoryItemCategoryCreateOrConnectWithoutBusinessUnitInput = {
  create: InventoryItemCategoryCreateWithoutBusinessUnitInput;
  where: InventoryItemCategoryWhereUniqueInput;
};

export type InventoryItemCategoryCreateOrConnectWithoutInventoryItemsInput = {
  create: InventoryItemCategoryCreateWithoutInventoryItemsInput;
  where: InventoryItemCategoryWhereUniqueInput;
};

export type InventoryItemCategoryCreateWithoutBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCategoryCreateWithoutInventoryItemsInput = {
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutInventoryItemCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCategoryListRelationFilter = {
  every?: InputMaybe<InventoryItemCategoryWhereInput>;
  none?: InputMaybe<InventoryItemCategoryWhereInput>;
  some?: InputMaybe<InventoryItemCategoryWhereInput>;
};

export type InventoryItemCategoryNameBusinessUnitIdCompoundUniqueInput = {
  businessUnitId: Scalars['Int'];
  name: Scalars['String'];
};

export type InventoryItemCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InventoryItemCategoryRelationFilter = {
  is?: InputMaybe<InventoryItemCategoryWhereInput>;
  isNot?: InputMaybe<InventoryItemCategoryWhereInput>;
};

export type InventoryItemCategoryWhereInput = {
  AND?: InputMaybe<Array<InventoryItemCategoryWhereInput>>;
  NOT?: InputMaybe<Array<InventoryItemCategoryWhereInput>>;
  OR?: InputMaybe<Array<InventoryItemCategoryWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItems?: InputMaybe<InventoryItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InventoryItemCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name_businessUnitId?: InputMaybe<InventoryItemCategoryNameBusinessUnitIdCompoundUniqueInput>;
};

export type InventoryItemCreateManyBusinessUnitInput = {
  categoryId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplierId: Scalars['Int'];
  unitOfMeasureForPackageId?: InputMaybe<Scalars['Int']>;
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManyBusinessUnitInputEnvelope = {
  data: Array<InventoryItemCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCreateManyCategoryInput = {
  businessUnitId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplierId: Scalars['Int'];
  unitOfMeasureForPackageId?: InputMaybe<Scalars['Int']>;
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManyCategoryInputEnvelope = {
  data: Array<InventoryItemCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCreateManySupplierInput = {
  businessUnitId: Scalars['Int'];
  categoryId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  quantity: Scalars['Float'];
  sector: Sector;
  unitOfMeasureForPackageId?: InputMaybe<Scalars['Int']>;
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManySupplierInputEnvelope = {
  data: Array<InventoryItemCreateManySupplierInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCreateManyUnitOfMeasureForPackageInput = {
  businessUnitId: Scalars['Int'];
  categoryId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplierId: Scalars['Int'];
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManyUnitOfMeasureForPackageInputEnvelope = {
  data: Array<InventoryItemCreateManyUnitOfMeasureForPackageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCreateManyUnitOfMeasureInput = {
  businessUnitId: Scalars['Int'];
  categoryId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplierId: Scalars['Int'];
  unitOfMeasureForPackageId?: InputMaybe<Scalars['Int']>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManyUnitOfMeasureInputEnvelope = {
  data: Array<InventoryItemCreateManyUnitOfMeasureInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<InventoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<InventoryItemCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<InventoryItemCreateManyBusinessUnitInputEnvelope>;
};

export type InventoryItemCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<InventoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<InventoryItemCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<InventoryItemCreateManyCategoryInputEnvelope>;
};

export type InventoryItemCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<InventoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<InventoryItemCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<InventoryItemCreateManySupplierInputEnvelope>;
};

export type InventoryItemCreateNestedManyWithoutUnitOfMeasureForPackageInput = {
  connect?: InputMaybe<Array<InventoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCreateOrConnectWithoutUnitOfMeasureForPackageInput>>;
  create?: InputMaybe<Array<InventoryItemCreateWithoutUnitOfMeasureForPackageInput>>;
  createMany?: InputMaybe<InventoryItemCreateManyUnitOfMeasureForPackageInputEnvelope>;
};

export type InventoryItemCreateNestedManyWithoutUnitOfMeasureInput = {
  connect?: InputMaybe<Array<InventoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCreateOrConnectWithoutUnitOfMeasureInput>>;
  create?: InputMaybe<Array<InventoryItemCreateWithoutUnitOfMeasureInput>>;
  createMany?: InputMaybe<InventoryItemCreateManyUnitOfMeasureInputEnvelope>;
};

export type InventoryItemCreateNestedOneWithoutInventoryTransactionsInput = {
  connect?: InputMaybe<InventoryItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InventoryItemCreateOrConnectWithoutInventoryTransactionsInput>;
  create?: InputMaybe<InventoryItemCreateWithoutInventoryTransactionsInput>;
};

export type InventoryItemCreateNestedOneWithoutProductSalesInput = {
  connect?: InputMaybe<InventoryItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InventoryItemCreateOrConnectWithoutProductSalesInput>;
  create?: InputMaybe<InventoryItemCreateWithoutProductSalesInput>;
};

export type InventoryItemCreateNestedOneWithoutPurchasesInput = {
  connect?: InputMaybe<InventoryItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InventoryItemCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<InventoryItemCreateWithoutPurchasesInput>;
};

export type InventoryItemCreateNestedOneWithoutRecipeItemsInput = {
  connect?: InputMaybe<InventoryItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InventoryItemCreateOrConnectWithoutRecipeItemsInput>;
  create?: InputMaybe<InventoryItemCreateWithoutRecipeItemsInput>;
};

export type InventoryItemCreateOrConnectWithoutBusinessUnitInput = {
  create: InventoryItemCreateWithoutBusinessUnitInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutCategoryInput = {
  create: InventoryItemCreateWithoutCategoryInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutInventoryTransactionsInput = {
  create: InventoryItemCreateWithoutInventoryTransactionsInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutProductSalesInput = {
  create: InventoryItemCreateWithoutProductSalesInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutPurchasesInput = {
  create: InventoryItemCreateWithoutPurchasesInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutRecipeItemsInput = {
  create: InventoryItemCreateWithoutRecipeItemsInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutSupplierInput = {
  create: InventoryItemCreateWithoutSupplierInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutUnitOfMeasureForPackageInput = {
  create: InventoryItemCreateWithoutUnitOfMeasureForPackageInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateOrConnectWithoutUnitOfMeasureInput = {
  create: InventoryItemCreateWithoutUnitOfMeasureInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateWithoutBusinessUnitInput = {
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutCategoryInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutInventoryTransactionsInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutProductSalesInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutPurchasesInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutRecipeItemsInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutSupplierInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutUnitOfMeasureForPackageInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasure: UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemCreateWithoutUnitOfMeasureInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutInventoryItemsInput;
  category: InventoryItemCategoryCreateNestedOneWithoutInventoryItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutInventoryItemInput>;
  itemType: ItemType;
  minimumQuantity?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packageQuantity?: InputMaybe<Scalars['Float']>;
  positionOnCountInventory: Scalars['Int'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutInventoryItemInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutInventoryItemInput>;
  sector: Sector;
  supplier: SupplierCreateNestedOneWithoutInventoryItemsInput;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastes?: InputMaybe<WasteCreateNestedManyWithoutInventoryItemInput>;
};

export type InventoryItemListRelationFilter = {
  every?: InputMaybe<InventoryItemWhereInput>;
  none?: InputMaybe<InventoryItemWhereInput>;
  some?: InputMaybe<InventoryItemWhereInput>;
};

export type InventoryItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InventoryItemRelationFilter = {
  is?: InputMaybe<InventoryItemWhereInput>;
  isNot?: InputMaybe<InventoryItemWhereInput>;
};

export type InventoryItemWhereInput = {
  AND?: InputMaybe<Array<InventoryItemWhereInput>>;
  NOT?: InputMaybe<Array<InventoryItemWhereInput>>;
  OR?: InputMaybe<Array<InventoryItemWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntFilter>;
  category?: InputMaybe<InventoryItemCategoryRelationFilter>;
  categoryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryTransactions?: InputMaybe<InventoryTransactionListRelationFilter>;
  itemType?: InputMaybe<EnumItemTypeFilter>;
  minimumQuantity?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  packageQuantity?: InputMaybe<FloatNullableFilter>;
  positionOnCountInventory?: InputMaybe<IntFilter>;
  productSales?: InputMaybe<ProductSaleListRelationFilter>;
  purchases?: InputMaybe<PurchaseListRelationFilter>;
  quantity?: InputMaybe<FloatFilter>;
  recipeItems?: InputMaybe<RecipeItemListRelationFilter>;
  sector?: InputMaybe<EnumSectorFilter>;
  supplier?: InputMaybe<SupplierRelationFilter>;
  supplierId?: InputMaybe<IntFilter>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureRelationFilter>;
  unitOfMeasureForPackage?: InputMaybe<UnitOfMeasureRelationFilter>;
  unitOfMeasureForPackageId?: InputMaybe<IntNullableFilter>;
  unitOfMeasureId?: InputMaybe<IntFilter>;
  unitPrice?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wastes?: InputMaybe<WasteListRelationFilter>;
};

export type InventoryItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type InventoryItemWithLastTransaction = {
  __typename?: 'InventoryItemWithLastTransaction';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  lastTransaction?: Maybe<InventoryTransaction>;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplier: Supplier;
  unitOfMeasure: UnitOfMeasure;
  unitPrice: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type InventoryTransaction = {
  __typename?: 'InventoryTransaction';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItem: InventoryItem;
  inventoryItemId: Scalars['Int'];
  observation?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  type: TransactionType;
  unitPrice?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type InventoryTransactionCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  observation?: InputMaybe<Scalars['String']>;
  packageQuantity?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  type: TransactionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type InventoryTransactionCreateManyInventoryItemInputEnvelope = {
  data: Array<InventoryTransactionCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryTransactionCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  inventoryItemId: Scalars['Int'];
  observation?: InputMaybe<Scalars['String']>;
  packageQuantity?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  type: TransactionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryTransactionCreateManyUserInputEnvelope = {
  data: Array<InventoryTransactionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryTransactionCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<InventoryTransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryTransactionCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<InventoryTransactionCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<InventoryTransactionCreateManyInventoryItemInputEnvelope>;
};

export type InventoryTransactionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<InventoryTransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryTransactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<InventoryTransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<InventoryTransactionCreateManyUserInputEnvelope>;
};

export type InventoryTransactionCreateOrConnectWithoutInventoryItemInput = {
  create: InventoryTransactionCreateWithoutInventoryItemInput;
  where: InventoryTransactionWhereUniqueInput;
};

export type InventoryTransactionCreateOrConnectWithoutUserInput = {
  create: InventoryTransactionCreateWithoutUserInput;
  where: InventoryTransactionWhereUniqueInput;
};

export type InventoryTransactionCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  observation?: InputMaybe<Scalars['String']>;
  packageQuantity?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  type: TransactionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutInventoryTransactionsInput;
};

export type InventoryTransactionCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItem: InventoryItemCreateNestedOneWithoutInventoryTransactionsInput;
  observation?: InputMaybe<Scalars['String']>;
  packageQuantity?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  type: TransactionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryTransactionListRelationFilter = {
  every?: InputMaybe<InventoryTransactionWhereInput>;
  none?: InputMaybe<InventoryTransactionWhereInput>;
  some?: InputMaybe<InventoryTransactionWhereInput>;
};

export type InventoryTransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InventoryTransactionWhereInput = {
  AND?: InputMaybe<Array<InventoryTransactionWhereInput>>;
  NOT?: InputMaybe<Array<InventoryTransactionWhereInput>>;
  OR?: InputMaybe<Array<InventoryTransactionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  observation?: InputMaybe<StringNullableFilter>;
  packageQuantity?: InputMaybe<FloatNullableFilter>;
  quantity?: InputMaybe<FloatFilter>;
  type?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type InventoryTransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum ItemType {
  Consumable = 'CONSUMABLE',
  NonPerishable = 'NON_PERISHABLE',
  Perishable = 'PERISHABLE'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Menu = {
  __typename?: 'Menu';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MenuCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuCreateManyBusinessUnitInputEnvelope = {
  data: Array<MenuCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MenuCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<MenuCreateManyBusinessUnitInputEnvelope>;
};

export type MenuCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutProductsInput>>;
};

export type MenuCreateOrConnectWithoutBusinessUnitInput = {
  create: MenuCreateWithoutBusinessUnitInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateOrConnectWithoutProductsInput = {
  create: MenuCreateWithoutProductsInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateWithoutBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutMenusInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuCreateWithoutProductsInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutMenusInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuListRelationFilter = {
  every?: InputMaybe<MenuWhereInput>;
  none?: InputMaybe<MenuWhereInput>;
  some?: InputMaybe<MenuWhereInput>;
};

export type MenuOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MenuWhereInput = {
  AND?: InputMaybe<Array<MenuWhereInput>>;
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  OR?: InputMaybe<Array<MenuWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MenuWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adjustInventory: Scalars['Boolean'];
  createInventoryItem: InventoryItem;
  createInventoryItemCategory: InventoryItemCategory;
  createMenuItem: Menu;
  createProduct: Product;
  createProductSale: ProductSale;
  createPurchase: Purchase;
  createRecipeItem: RecipeItem;
  createSupplier: Supplier;
  createUnitOfMeasure: UnitOfMeasure;
  createUser: User;
  createWaste: Waste;
  destroyAccount: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  getBulkSignedS3UrlForPut?: Maybe<Array<SignedResponse>>;
  getSignedS3UrlForPut?: Maybe<SignedResponse>;
  login: AuthResponse;
  register: AuthResponse;
  removeBusinessUnit: Scalars['Boolean'];
  removeFranchisor: Scalars['Boolean'];
  removeInventoryItem: Scalars['Boolean'];
  removeMenuItem: Scalars['Boolean'];
  removeProduct: Scalars['Boolean'];
  removePurchase: Scalars['Boolean'];
  removeRecipeItem: Scalars['Boolean'];
  removeSupplier: Scalars['Boolean'];
  removeUnitOfMeasure: Scalars['Boolean'];
  removeWaste: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  updateInventoryItem?: Maybe<InventoryItem>;
  updateInventoryItemCategory: InventoryItemCategory;
  updateMe: User;
  updateMenuItem?: Maybe<Menu>;
  updatePositionOrder: Array<InventoryItem>;
  updateProduct?: Maybe<Product>;
  updateProductSale?: Maybe<ProductSale>;
  updatePurchase?: Maybe<Purchase>;
  updateRecipeItem?: Maybe<RecipeItem>;
  updateSupplier?: Maybe<Supplier>;
  updateUnitOfMeasure?: Maybe<UnitOfMeasure>;
  updateWaste?: Maybe<Waste>;
};


export type MutationAdjustInventoryArgs = {
  adjustments: Array<InventoryAdjustmentDto>;
};


export type MutationCreateInventoryItemArgs = {
  data: CreateInventoryItemDto;
};


export type MutationCreateInventoryItemCategoryArgs = {
  data: CreateInventoryItemCategoryInput;
};


export type MutationCreateMenuItemArgs = {
  data: CreateMenuItemDto;
};


export type MutationCreateProductArgs = {
  data: CreateProductDto;
};


export type MutationCreateProductSaleArgs = {
  data: CreateProductSaleDto;
};


export type MutationCreatePurchaseArgs = {
  data: CreatePurchaseDto;
};


export type MutationCreateRecipeItemArgs = {
  data: CreateRecipeItemDto;
};


export type MutationCreateSupplierArgs = {
  data: CreateSupplierDto;
};


export type MutationCreateUnitOfMeasureArgs = {
  data: CreateUnitOfMeasureDto;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateWasteArgs = {
  data: CreateWasteDto;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGetBulkSignedS3UrlForPutArgs = {
  data: S3BulkSignedUrlInput;
};


export type MutationGetSignedS3UrlForPutArgs = {
  data: S3SignedUrlInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationRemoveBusinessUnitArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFranchisorArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveInventoryItemArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveMenuItemArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePurchaseArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveRecipeItemArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveSupplierArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUnitOfMeasureArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveWasteArgs = {
  id: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationUpdateInventoryItemArgs = {
  data: UpdateInventoryItemDto;
  id: Scalars['Int'];
};


export type MutationUpdateInventoryItemCategoryArgs = {
  businessUnitId: Scalars['Int'];
  data: UpdateInventoryItemCategoryInput;
};


export type MutationUpdateMeArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateMenuItemArgs = {
  data: UpdateMenuItemDto;
  id: Scalars['Int'];
};


export type MutationUpdatePositionOrderArgs = {
  items: Array<UpdatePositionOrderItemInput>;
};


export type MutationUpdateProductArgs = {
  data: UpdateProductDto;
  id: Scalars['Int'];
};


export type MutationUpdateProductSaleArgs = {
  data: UpdateProductSaleDto;
  id: Scalars['Int'];
};


export type MutationUpdatePurchaseArgs = {
  data: UpdatePurchaseDto;
  id: Scalars['Int'];
};


export type MutationUpdateRecipeItemArgs = {
  data: UpdateRecipeItemDto;
  id: Scalars['Int'];
};


export type MutationUpdateSupplierArgs = {
  data: UpdateSupplierDto;
  id: Scalars['Int'];
};


export type MutationUpdateUnitOfMeasureArgs = {
  data: UpdateUnitOfMeasureDto;
  id: Scalars['Int'];
};


export type MutationUpdateWasteArgs = {
  data: UpdateWasteDto;
  id: Scalars['Int'];
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumItemTypeFilter = {
  equals?: InputMaybe<ItemType>;
  in?: InputMaybe<Array<ItemType>>;
  not?: InputMaybe<NestedEnumItemTypeFilter>;
  notIn?: InputMaybe<Array<ItemType>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumSectorFilter = {
  equals?: InputMaybe<Sector>;
  in?: InputMaybe<Array<Sector>>;
  not?: InputMaybe<NestedEnumSectorFilter>;
  notIn?: InputMaybe<Array<Sector>>;
};

export type NestedEnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<PaymentMethodWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentMethodCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<PaymentMethodCreateWithoutTransactionsInput>;
};

export type PaymentMethodCreateOrConnectWithoutTransactionsInput = {
  create: PaymentMethodCreateWithoutTransactionsInput;
  where: PaymentMethodWhereUniqueInput;
};

export type PaymentMethodCreateWithoutTransactionsInput = {
  fee: Scalars['Float'];
  name: Scalars['String'];
};

export type PaymentMethodRelationFilter = {
  is?: InputMaybe<PaymentMethodWhereInput>;
  isNot?: InputMaybe<PaymentMethodWhereInput>;
};

export type PaymentMethodWhereInput = {
  AND?: InputMaybe<Array<PaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<PaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<PaymentMethodWhereInput>>;
  fee?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
};

export type PaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  menus?: Maybe<Array<Menu>>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productSales?: Maybe<Array<ProductSale>>;
  recipeItems?: Maybe<Array<RecipeItem>>;
  updatedAt: Scalars['DateTime'];
};

export type ProductCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateManyBusinessUnitInputEnvelope = {
  data: Array<ProductCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<ProductCreateManyBusinessUnitInputEnvelope>;
};

export type ProductCreateNestedManyWithoutMenusInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutMenusInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutMenusInput>>;
};

export type ProductCreateNestedOneWithoutProductSalesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductSalesInput>;
  create?: InputMaybe<ProductCreateWithoutProductSalesInput>;
};

export type ProductCreateNestedOneWithoutRecipeItemsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutRecipeItemsInput>;
  create?: InputMaybe<ProductCreateWithoutRecipeItemsInput>;
};

export type ProductCreateOrConnectWithoutBusinessUnitInput = {
  create: ProductCreateWithoutBusinessUnitInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutMenusInput = {
  create: ProductCreateWithoutMenusInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductSalesInput = {
  create: ProductCreateWithoutProductSalesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutRecipeItemsInput = {
  create: ProductCreateWithoutRecipeItemsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutProductsInput>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutProductInput>;
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutProductInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateWithoutMenusInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutProductInput>;
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutProductInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateWithoutProductSalesInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutProductsInput>;
  name: Scalars['String'];
  price: Scalars['Float'];
  recipeItems?: InputMaybe<RecipeItemCreateNestedManyWithoutProductInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateWithoutRecipeItemsInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutProductsInput>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutProductInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export type ProductSale = {
  __typename?: 'ProductSale';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItem?: Maybe<InventoryItem>;
  inventoryItemId?: Maybe<Scalars['Int']>;
  product: Product;
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
  saleDate: Scalars['DateTime'];
  unitOfMeasure?: Maybe<UnitOfMeasure>;
  unitOfMeasureId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ProductSaleCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  unitOfMeasureId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductSaleCreateManyInventoryItemInputEnvelope = {
  data: Array<ProductSaleCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductSaleCreateManyProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  inventoryItemId?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  unitOfMeasureId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductSaleCreateManyProductInputEnvelope = {
  data: Array<ProductSaleCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductSaleCreateManyUnitOfMeasureInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  inventoryItemId?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductSaleCreateManyUnitOfMeasureInputEnvelope = {
  data: Array<ProductSaleCreateManyUnitOfMeasureInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductSaleCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<ProductSaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductSaleCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<ProductSaleCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<ProductSaleCreateManyInventoryItemInputEnvelope>;
};

export type ProductSaleCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductSaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductSaleCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductSaleCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductSaleCreateManyProductInputEnvelope>;
};

export type ProductSaleCreateNestedManyWithoutUnitOfMeasureInput = {
  connect?: InputMaybe<Array<ProductSaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductSaleCreateOrConnectWithoutUnitOfMeasureInput>>;
  create?: InputMaybe<Array<ProductSaleCreateWithoutUnitOfMeasureInput>>;
  createMany?: InputMaybe<ProductSaleCreateManyUnitOfMeasureInputEnvelope>;
};

export type ProductSaleCreateOrConnectWithoutInventoryItemInput = {
  create: ProductSaleCreateWithoutInventoryItemInput;
  where: ProductSaleWhereUniqueInput;
};

export type ProductSaleCreateOrConnectWithoutProductInput = {
  create: ProductSaleCreateWithoutProductInput;
  where: ProductSaleWhereUniqueInput;
};

export type ProductSaleCreateOrConnectWithoutUnitOfMeasureInput = {
  create: ProductSaleCreateWithoutUnitOfMeasureInput;
  where: ProductSaleWhereUniqueInput;
};

export type ProductSaleCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  product: ProductCreateNestedOneWithoutProductSalesInput;
  quantity: Scalars['Int'];
  unitOfMeasure?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutProductSalesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductSaleCreateWithoutProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItem?: InputMaybe<InventoryItemCreateNestedOneWithoutProductSalesInput>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  unitOfMeasure?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutProductSalesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductSaleCreateWithoutUnitOfMeasureInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItem?: InputMaybe<InventoryItemCreateNestedOneWithoutProductSalesInput>;
  price: Scalars['Float'];
  product: ProductCreateNestedOneWithoutProductSalesInput;
  quantity: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductSaleListRelationFilter = {
  every?: InputMaybe<ProductSaleWhereInput>;
  none?: InputMaybe<ProductSaleWhereInput>;
  some?: InputMaybe<ProductSaleWhereInput>;
};

export type ProductSaleWhereInput = {
  AND?: InputMaybe<Array<ProductSaleWhereInput>>;
  NOT?: InputMaybe<Array<ProductSaleWhereInput>>;
  OR?: InputMaybe<Array<ProductSaleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureRelationFilter>;
  unitOfMeasureId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductSaleWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  menus?: InputMaybe<MenuListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  productSales?: InputMaybe<ProductSaleListRelationFilter>;
  recipeItems?: InputMaybe<RecipeItemListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Purchase = {
  __typename?: 'Purchase';
  businessUnitId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  supplierId: Scalars['Int'];
  totalPrice: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type PurchaseCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  inventoryItemId: Scalars['Int'];
  purchaseDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  supplierId: Scalars['Int'];
  totalPrice: Scalars['Float'];
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseCreateManyBusinessUnitInputEnvelope = {
  data: Array<PurchaseCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyInventoryItemInput = {
  businessUnitId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  supplierId: Scalars['Int'];
  totalPrice: Scalars['Float'];
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseCreateManyInventoryItemInputEnvelope = {
  data: Array<PurchaseCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManySupplierInput = {
  businessUnitId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  inventoryItemId: Scalars['Int'];
  purchaseDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  totalPrice: Scalars['Float'];
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseCreateManySupplierInputEnvelope = {
  data: Array<PurchaseCreateManySupplierInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<PurchaseCreateManyBusinessUnitInputEnvelope>;
};

export type PurchaseCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<PurchaseCreateManyInventoryItemInputEnvelope>;
};

export type PurchaseCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<PurchaseCreateManySupplierInputEnvelope>;
};

export type PurchaseCreateOrConnectWithoutBusinessUnitInput = {
  create: PurchaseCreateWithoutBusinessUnitInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateOrConnectWithoutInventoryItemInput = {
  create: PurchaseCreateWithoutInventoryItemInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateOrConnectWithoutSupplierInput = {
  create: PurchaseCreateWithoutSupplierInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateWithoutBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItem: InventoryItemCreateNestedOneWithoutPurchasesInput;
  purchaseDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  supplier: SupplierCreateNestedOneWithoutPurchasesInput;
  totalPrice: Scalars['Float'];
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseCreateWithoutInventoryItemInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutPurchasesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  supplier: SupplierCreateNestedOneWithoutPurchasesInput;
  totalPrice: Scalars['Float'];
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseCreateWithoutSupplierInput = {
  businessUnit: BusinessUnitCreateNestedOneWithoutPurchasesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItem: InventoryItemCreateNestedOneWithoutPurchasesInput;
  purchaseDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  totalPrice: Scalars['Float'];
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseListRelationFilter = {
  every?: InputMaybe<PurchaseWhereInput>;
  none?: InputMaybe<PurchaseWhereInput>;
  some?: InputMaybe<PurchaseWhereInput>;
};

export type PurchaseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PurchaseWhereInput = {
  AND?: InputMaybe<Array<PurchaseWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseWhereInput>>;
  OR?: InputMaybe<Array<PurchaseWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  purchaseDate?: InputMaybe<DateTimeFilter>;
  quantity?: InputMaybe<FloatFilter>;
  supplier?: InputMaybe<SupplierRelationFilter>;
  supplierId?: InputMaybe<IntFilter>;
  totalPrice?: InputMaybe<FloatFilter>;
  unitPrice?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PurchaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitBySlug?: Maybe<BusinessUnit>;
  businessUnits: Array<BusinessUnit>;
  calculateTotalStockValue: Scalars['Float'];
  franchisor?: Maybe<Franchisor>;
  franchisorBySlug?: Maybe<Franchisor>;
  franchisors: Array<Franchisor>;
  getSignedS3UrlForGet?: Maybe<Scalars['String']>;
  inventoryItem?: Maybe<InventoryItem>;
  inventoryItemCategories: Array<InventoryItemCategory>;
  inventoryItemCategoriesByBusinessUnit: Array<InventoryItemCategory>;
  inventoryItemCategory?: Maybe<InventoryItemCategory>;
  inventoryItemDetails: InventoryItem;
  inventoryItems: Array<InventoryItem>;
  inventoryItemsByBusinessUnit: Array<InventoryItem>;
  inventoryItemsWithLastTransaction: Array<InventoryItemWithLastTransaction>;
  inventoryTransaction?: Maybe<InventoryTransaction>;
  inventoryTransactions: Array<InventoryTransaction>;
  inventoryTransactionsByBusinessUnit: Array<InventoryTransaction>;
  inventoryTransactionsByInventoryItemId: Array<InventoryTransaction>;
  itemsInOutOfStock: Scalars['Int'];
  itemsInUrgent: Scalars['Int'];
  me?: Maybe<User>;
  menuItem?: Maybe<Menu>;
  menuItems: Array<Menu>;
  product?: Maybe<Product>;
  productSale?: Maybe<ProductSale>;
  productSales: Array<ProductSale>;
  products: Array<Product>;
  purchase?: Maybe<Purchase>;
  purchases: Array<Purchase>;
  recipeItem?: Maybe<RecipeItem>;
  recipeItems: Array<RecipeItem>;
  refreshToken?: Maybe<RefreshTokenResponse>;
  supplier?: Maybe<Supplier>;
  suppliers: Array<Supplier>;
  suppliersByBusinessUnit: Array<Supplier>;
  unitOfMeasure?: Maybe<UnitOfMeasure>;
  unitsOfMeasure: Array<UnitOfMeasure>;
  user?: Maybe<User>;
  users: UsersResponse;
  waste?: Maybe<Waste>;
  wastes: Array<Waste>;
};


export type QueryBusinessUnitArgs = {
  id: Scalars['Int'];
};


export type QueryBusinessUnitBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCalculateTotalStockValueArgs = {
  businessUnitId: Scalars['Int'];
};


export type QueryFranchisorArgs = {
  id: Scalars['Int'];
};


export type QueryFranchisorBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetSignedS3UrlForGetArgs = {
  key: Scalars['String'];
};


export type QueryInventoryItemArgs = {
  id: Scalars['Int'];
};


export type QueryInventoryItemCategoriesByBusinessUnitArgs = {
  businessUnitId: Scalars['Int'];
};


export type QueryInventoryItemCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryInventoryItemDetailsArgs = {
  id: Scalars['Int'];
};


export type QueryInventoryItemsByBusinessUnitArgs = {
  businessUnitId: Scalars['Int'];
  sector?: InputMaybe<Scalars['String']>;
};


export type QueryInventoryItemsWithLastTransactionArgs = {
  businessUnitId: Scalars['Int'];
  sector?: InputMaybe<Sector>;
};


export type QueryInventoryTransactionArgs = {
  id: Scalars['Int'];
};


export type QueryInventoryTransactionsByBusinessUnitArgs = {
  businessUnitId: Scalars['Int'];
};


export type QueryInventoryTransactionsByInventoryItemIdArgs = {
  inventoryItemId: Scalars['Int'];
};


export type QueryItemsInOutOfStockArgs = {
  businessUnitId: Scalars['Int'];
};


export type QueryItemsInUrgentArgs = {
  businessUnitId: Scalars['Int'];
};


export type QueryMenuItemArgs = {
  id: Scalars['Int'];
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryProductSaleArgs = {
  id: Scalars['Int'];
};


export type QueryPurchaseArgs = {
  id: Scalars['Int'];
};


export type QueryRecipeItemArgs = {
  id: Scalars['Int'];
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QuerySupplierArgs = {
  id: Scalars['Int'];
};


export type QuerySuppliersByBusinessUnitArgs = {
  businessUnitId: Scalars['Int'];
};


export type QueryUnitOfMeasureArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryWasteArgs = {
  id: Scalars['Int'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RecipeItem = {
  __typename?: 'RecipeItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItem: InventoryItem;
  inventoryItemId: Scalars['Int'];
  product: Product;
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type RecipeItemCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeItemCreateManyInventoryItemInputEnvelope = {
  data: Array<RecipeItemCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeItemCreateManyProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeItemCreateManyProductInputEnvelope = {
  data: Array<RecipeItemCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeItemCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<RecipeItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeItemCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<RecipeItemCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<RecipeItemCreateManyInventoryItemInputEnvelope>;
};

export type RecipeItemCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<RecipeItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeItemCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<RecipeItemCreateWithoutProductInput>>;
  createMany?: InputMaybe<RecipeItemCreateManyProductInputEnvelope>;
};

export type RecipeItemCreateOrConnectWithoutInventoryItemInput = {
  create: RecipeItemCreateWithoutInventoryItemInput;
  where: RecipeItemWhereUniqueInput;
};

export type RecipeItemCreateOrConnectWithoutProductInput = {
  create: RecipeItemCreateWithoutProductInput;
  where: RecipeItemWhereUniqueInput;
};

export type RecipeItemCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  product: ProductCreateNestedOneWithoutRecipeItemsInput;
  quantity: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeItemCreateWithoutProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItem: InventoryItemCreateNestedOneWithoutRecipeItemsInput;
  quantity: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeItemListRelationFilter = {
  every?: InputMaybe<RecipeItemWhereInput>;
  none?: InputMaybe<RecipeItemWhereInput>;
  some?: InputMaybe<RecipeItemWhereInput>;
};

export type RecipeItemWhereInput = {
  AND?: InputMaybe<Array<RecipeItemWhereInput>>;
  NOT?: InputMaybe<Array<RecipeItemWhereInput>>;
  OR?: InputMaybe<Array<RecipeItemWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RegisterInput = {
  businessUnitId: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchiseeId: Scalars['Float'];
  franchisorId: Scalars['Float'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export enum Role {
  Employee = 'EMPLOYEE',
  Finance = 'FINANCE',
  Franchisee = 'FRANCHISEE',
  Franchisor = 'FRANCHISOR',
  KitchenStaff = 'KITCHEN_STAFF',
  SaloonStaff = 'SALOON_STAFF',
  UnitManager = 'UNIT_MANAGER'
}

export type S3BulkSignedUrlInput = {
  files: Array<S3SignedUrlInput>;
};

export type S3SignedUrlInput = {
  fileType: Scalars['String'];
  key: Scalars['String'];
};

export enum Sector {
  Bar = 'BAR',
  Kitchen = 'KITCHEN',
  Office = 'OFFICE'
}

export type SignedResponse = {
  __typename?: 'SignedResponse';
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
  url: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  address?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SupplierCreateNestedManyWithoutBusinessUnitsInput = {
  connect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SupplierCreateOrConnectWithoutBusinessUnitsInput>>;
  create?: InputMaybe<Array<SupplierCreateWithoutBusinessUnitsInput>>;
};

export type SupplierCreateNestedOneWithoutInventoryItemsInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutInventoryItemsInput>;
  create?: InputMaybe<SupplierCreateWithoutInventoryItemsInput>;
};

export type SupplierCreateNestedOneWithoutPurchasesInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<SupplierCreateWithoutPurchasesInput>;
};

export type SupplierCreateOrConnectWithoutBusinessUnitsInput = {
  create: SupplierCreateWithoutBusinessUnitsInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutInventoryItemsInput = {
  create: SupplierCreateWithoutInventoryItemsInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutPurchasesInput = {
  create: SupplierCreateWithoutPurchasesInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateWithoutBusinessUnitsInput = {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutSupplierInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SupplierCreateWithoutInventoryItemsInput = {
  address?: InputMaybe<Scalars['String']>;
  businessUnits?: InputMaybe<BusinessUnitCreateNestedManyWithoutSuppliersInput>;
  contact?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutSupplierInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SupplierCreateWithoutPurchasesInput = {
  address?: InputMaybe<Scalars['String']>;
  businessUnits?: InputMaybe<BusinessUnitCreateNestedManyWithoutSuppliersInput>;
  contact?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SupplierListRelationFilter = {
  every?: InputMaybe<SupplierWhereInput>;
  none?: InputMaybe<SupplierWhereInput>;
  some?: InputMaybe<SupplierWhereInput>;
};

export type SupplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SupplierRelationFilter = {
  is?: InputMaybe<SupplierWhereInput>;
  isNot?: InputMaybe<SupplierWhereInput>;
};

export type SupplierWhereInput = {
  AND?: InputMaybe<Array<SupplierWhereInput>>;
  NOT?: InputMaybe<Array<SupplierWhereInput>>;
  OR?: InputMaybe<Array<SupplierWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  businessUnits?: InputMaybe<BusinessUnitListRelationFilter>;
  contact?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItems?: InputMaybe<InventoryItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  purchases?: InputMaybe<PurchaseListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SupplierWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type TransactionCreateManyCashFlowInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  grossAmount: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  netAmount: Scalars['Float'];
  paymentMethodId: Scalars['Int'];
};

export type TransactionCreateManyCashFlowInputEnvelope = {
  data: Array<TransactionCreateManyCashFlowInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutCashFlowInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutCashFlowInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutCashFlowInput>>;
  createMany?: InputMaybe<TransactionCreateManyCashFlowInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutCashFlowInput = {
  create: TransactionCreateWithoutCashFlowInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutCashFlowInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  grossAmount: Scalars['Float'];
  netAmount: Scalars['Float'];
  paymentMethod: PaymentMethodCreateNestedOneWithoutTransactionsInput;
};

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export enum TransactionType {
  Entry = 'ENTRY',
  Exit = 'EXIT',
  ManualEntry = 'MANUAL_ENTRY',
  ManualExit = 'MANUAL_EXIT'
}

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  cashFlow?: InputMaybe<CashFlowRelationFilter>;
  cashFlowId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  grossAmount?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  netAmount?: InputMaybe<FloatFilter>;
  paymentMethod?: InputMaybe<PaymentMethodRelationFilter>;
  paymentMethodId?: InputMaybe<IntFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  abbreviation: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UnitOfMeasureCreateNestedOneWithoutInventoryItemsInput = {
  connect?: InputMaybe<UnitOfMeasureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitOfMeasureCreateOrConnectWithoutInventoryItemsInput>;
  create?: InputMaybe<UnitOfMeasureCreateWithoutInventoryItemsInput>;
};

export type UnitOfMeasureCreateNestedOneWithoutInventoryPackagesInput = {
  connect?: InputMaybe<UnitOfMeasureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitOfMeasureCreateOrConnectWithoutInventoryPackagesInput>;
  create?: InputMaybe<UnitOfMeasureCreateWithoutInventoryPackagesInput>;
};

export type UnitOfMeasureCreateNestedOneWithoutProductSalesInput = {
  connect?: InputMaybe<UnitOfMeasureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitOfMeasureCreateOrConnectWithoutProductSalesInput>;
  create?: InputMaybe<UnitOfMeasureCreateWithoutProductSalesInput>;
};

export type UnitOfMeasureCreateOrConnectWithoutInventoryItemsInput = {
  create: UnitOfMeasureCreateWithoutInventoryItemsInput;
  where: UnitOfMeasureWhereUniqueInput;
};

export type UnitOfMeasureCreateOrConnectWithoutInventoryPackagesInput = {
  create: UnitOfMeasureCreateWithoutInventoryPackagesInput;
  where: UnitOfMeasureWhereUniqueInput;
};

export type UnitOfMeasureCreateOrConnectWithoutProductSalesInput = {
  create: UnitOfMeasureCreateWithoutProductSalesInput;
  where: UnitOfMeasureWhereUniqueInput;
};

export type UnitOfMeasureCreateWithoutInventoryItemsInput = {
  abbreviation: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryPackages?: InputMaybe<InventoryItemCreateNestedManyWithoutUnitOfMeasureForPackageInput>;
  name: Scalars['String'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutUnitOfMeasureInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnitOfMeasureCreateWithoutInventoryPackagesInput = {
  abbreviation: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutUnitOfMeasureInput>;
  name: Scalars['String'];
  productSales?: InputMaybe<ProductSaleCreateNestedManyWithoutUnitOfMeasureInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnitOfMeasureCreateWithoutProductSalesInput = {
  abbreviation: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItems?: InputMaybe<InventoryItemCreateNestedManyWithoutUnitOfMeasureInput>;
  inventoryPackages?: InputMaybe<InventoryItemCreateNestedManyWithoutUnitOfMeasureForPackageInput>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnitOfMeasureRelationFilter = {
  is?: InputMaybe<UnitOfMeasureWhereInput>;
  isNot?: InputMaybe<UnitOfMeasureWhereInput>;
};

export type UnitOfMeasureWhereInput = {
  AND?: InputMaybe<Array<UnitOfMeasureWhereInput>>;
  NOT?: InputMaybe<Array<UnitOfMeasureWhereInput>>;
  OR?: InputMaybe<Array<UnitOfMeasureWhereInput>>;
  abbreviation?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItems?: InputMaybe<InventoryItemListRelationFilter>;
  inventoryPackages?: InputMaybe<InventoryItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  productSales?: InputMaybe<ProductSaleListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UnitOfMeasureWhereUniqueInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateInventoryItemCategoryInput = {
  businessUnitId?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateInventoryItemDto = {
  businessUnitId: Scalars['Int'];
  categoryId: Scalars['Int'];
  itemType: ItemType;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  packageQuantity: Scalars['Float'];
  positionOnCountInventory: Scalars['Float'];
  quantity: Scalars['Float'];
  sector: Sector;
  supplierId?: InputMaybe<Scalars['Int']>;
  unitOfMeasureForPackageId: Scalars['Int'];
  unitOfMeasureId: Scalars['Int'];
  unitPrice: Scalars['Float'];
};

export type UpdateMenuItemDto = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePositionOrderItemInput = {
  id: Scalars['Int'];
  newOrderPosition: Scalars['Int'];
};

export type UpdateProductDto = {
  businessUnitId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type UpdateProductSaleDto = {
  inventoryItemId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  saleDate?: InputMaybe<Scalars['DateTime']>;
  unitOfMeasureId?: InputMaybe<Scalars['Int']>;
};

export type UpdatePurchaseDto = {
  businessUnitId: Scalars['Int'];
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  supplierId: Scalars['Int'];
  totalPrice: Scalars['Float'];
};

export type UpdateRecipeItemDto = {
  inventoryItemId: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type UpdateSupplierDto = {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateUnitOfMeasureDto = {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateWasteDto = {
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisor?: Maybe<Franchisor>;
  franchisorId?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  role: Role;
  updatedAt: Scalars['DateTime'];
};

export type UserCreateInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUsersInput>;
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutUserInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchiseeId?: InputMaybe<Scalars['Int']>;
  franchisorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyBusinessUnitInputEnvelope = {
  data: Array<UserCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyFranchiseeInput = {
  businessUnitId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyFranchiseeInputEnvelope = {
  data: Array<UserCreateManyFranchiseeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyFranchisorInput = {
  businessUnitId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchiseeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyFranchisorInputEnvelope = {
  data: Array<UserCreateManyFranchisorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<UserCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<UserCreateManyBusinessUnitInputEnvelope>;
};

export type UserCreateNestedManyWithoutFranchiseeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFranchiseeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFranchiseeInput>>;
  createMany?: InputMaybe<UserCreateManyFranchiseeInputEnvelope>;
};

export type UserCreateNestedManyWithoutFranchisorInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFranchisorInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFranchisorInput>>;
  createMany?: InputMaybe<UserCreateManyFranchisorInputEnvelope>;
};

export type UserCreateNestedOneWithoutInventoryTransactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInventoryTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutInventoryTransactionsInput>;
};

export type UserCreateOrConnectWithoutBusinessUnitInput = {
  create: UserCreateWithoutBusinessUnitInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFranchiseeInput = {
  create: UserCreateWithoutFranchiseeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFranchisorInput = {
  create: UserCreateWithoutFranchisorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInventoryTransactionsInput = {
  create: UserCreateWithoutInventoryTransactionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutBusinessUnitInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUsersInput>;
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutUserInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutFranchiseeInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutUserInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutFranchisorInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUsersInput>;
  inventoryTransactions?: InputMaybe<InventoryTransactionCreateNestedManyWithoutUserInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutInventoryTransactionsInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUsersInput>;
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  activityLogs?: InputMaybe<ActivityLogOrderByRelationAggregateInput>;
  businessUnit?: InputMaybe<BusinessUnitOrderByWithRelationInput>;
  businessUnitId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  franchisee?: InputMaybe<FranchiseeOrderByWithRelationInput>;
  franchiseeId?: InputMaybe<SortOrder>;
  franchisor?: InputMaybe<FranchisorOrderByWithRelationInput>;
  franchisorId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inventoryTransactions?: InputMaybe<InventoryTransactionOrderByRelationAggregateInput>;
  lastName?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  BusinessUnitId = 'businessUnitId',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  FranchiseeId = 'franchiseeId',
  FranchisorId = 'franchisorId',
  Id = 'id',
  LastName = 'lastName',
  Password = 'password',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activityLogs?: InputMaybe<ActivityLogListRelationFilter>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  franchisee?: InputMaybe<FranchiseeRelationFilter>;
  franchiseeId?: InputMaybe<IntNullableFilter>;
  franchisor?: InputMaybe<FranchisorRelationFilter>;
  franchisorId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryTransactions?: InputMaybe<InventoryTransactionListRelationFilter>;
  lastName?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count: Scalars['Int'];
  items: Array<User>;
};

export type Waste = {
  __typename?: 'Waste';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItemId: Scalars['Int'];
  quantity: Scalars['Float'];
  reason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  wasteDate: Scalars['DateTime'];
};

export type WasteCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wasteDate?: InputMaybe<Scalars['DateTime']>;
};

export type WasteCreateManyInventoryItemInputEnvelope = {
  data: Array<WasteCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WasteCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<WasteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WasteCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<WasteCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<WasteCreateManyInventoryItemInputEnvelope>;
};

export type WasteCreateOrConnectWithoutInventoryItemInput = {
  create: WasteCreateWithoutInventoryItemInput;
  where: WasteWhereUniqueInput;
};

export type WasteCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wasteDate?: InputMaybe<Scalars['DateTime']>;
};

export type WasteListRelationFilter = {
  every?: InputMaybe<WasteWhereInput>;
  none?: InputMaybe<WasteWhereInput>;
  some?: InputMaybe<WasteWhereInput>;
};

export type WasteWhereInput = {
  AND?: InputMaybe<Array<WasteWhereInput>>;
  NOT?: InputMaybe<Array<WasteWhereInput>>;
  OR?: InputMaybe<Array<WasteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wasteDate?: InputMaybe<DateTimeFilter>;
};

export type WasteWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type InventoryItemDocumentFragment = { __typename?: 'InventoryItem', id: number, name: string, itemType: ItemType, sector: Sector, positionOnCountInventory: number, quantity: number, minimumQuantity: number, packageQuantity?: number | null, unitPrice: number, supplier?: { __typename?: 'Supplier', id: number, name: string } | null, unitOfMeasure: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string }, unitOfMeasureForPackage?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, inventoryTransactions: Array<{ __typename?: 'InventoryTransaction', id: number, quantity: number, observation?: string | null, type: TransactionType, createdAt: string, updatedAt: string, user: { __typename?: 'User', id: number, firstName: string } }>, category?: { __typename?: 'InventoryItemCategory', id: number, name: string } | null };

export type InventoryTransactionDocumentFragment = { __typename?: 'InventoryTransaction', id: number, inventoryItemId: number, quantity: number, type: TransactionType, observation?: string | null, createdAt: string, updatedAt: string, user: { __typename?: 'User', id: number, firstName: string } };

export type CreateInventoryItemMutationVariables = Exact<{
  data: CreateInventoryItemDto;
}>;


export type CreateInventoryItemMutation = { __typename?: 'Mutation', createInventoryItem: { __typename?: 'InventoryItem', id: number, name: string, itemType: ItemType, sector: Sector, quantity: number, unitPrice: number, minimumQuantity: number, positionOnCountInventory: number, unitOfMeasureId: number, packageQuantity?: number | null, unitOfMeasureForPackageId?: number | null, businessUnitId: number, supplierId?: number | null, unitOfMeasure: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string }, unitOfMeasureForPackage?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, supplier?: { __typename?: 'Supplier', id: number, name: string } | null, inventoryTransactions: Array<{ __typename?: 'InventoryTransaction', id: number, quantity: number, type: TransactionType, observation?: string | null, createdAt: string, updatedAt: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string } }> } };

export type UpdateInventoryItemMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UpdateInventoryItemDto;
}>;


export type UpdateInventoryItemMutation = { __typename?: 'Mutation', updateInventoryItem?: { __typename?: 'InventoryItem', id: number, name: string, itemType: ItemType, sector: Sector, quantity: number, unitPrice: number, minimumQuantity: number, positionOnCountInventory: number, unitOfMeasureId: number, packageQuantity?: number | null, unitOfMeasureForPackageId?: number | null, businessUnitId: number, supplierId?: number | null, unitOfMeasure: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string }, unitOfMeasureForPackage?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, supplier?: { __typename?: 'Supplier', id: number, name: string } | null, inventoryTransactions: Array<{ __typename?: 'InventoryTransaction', id: number, quantity: number, type: TransactionType, observation?: string | null, createdAt: string, updatedAt: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string } }> } | null };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string, refreshToken: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, businessUnit?: { __typename?: 'BusinessUnit', name: string, slug: string } | null } } };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type AdjustInventoryItemsMutationVariables = Exact<{
  adjustments: Array<InventoryAdjustmentDto> | InventoryAdjustmentDto;
}>;


export type AdjustInventoryItemsMutation = { __typename?: 'Mutation', adjustInventory: boolean };

export type UpdatePositionOrderMutationVariables = Exact<{
  items: Array<UpdatePositionOrderItemInput> | UpdatePositionOrderItemInput;
}>;


export type UpdatePositionOrderMutation = { __typename?: 'Mutation', updatePositionOrder: Array<{ __typename?: 'InventoryItem', id: number, positionOnCountInventory: number }> };

export type DeleteInventoryItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInventoryItemMutation = { __typename?: 'Mutation', removeInventoryItem: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type CreateSupplierMutationVariables = Exact<{
  data: CreateSupplierDto;
}>;


export type CreateSupplierMutation = { __typename?: 'Mutation', createSupplier: { __typename?: 'Supplier', id: number, name: string, contact?: string | null, email: string, phone?: string | null, address?: string | null } };

export type UpdateSupplierMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UpdateSupplierDto;
}>;


export type UpdateSupplierMutation = { __typename?: 'Mutation', updateSupplier?: { __typename?: 'Supplier', id: number, name: string, contact?: string | null, email: string, phone?: string | null, address?: string | null } | null };

export type CreateInventoryItemCategoryMutationVariables = Exact<{
  data: CreateInventoryItemCategoryInput;
}>;


export type CreateInventoryItemCategoryMutation = { __typename?: 'Mutation', createInventoryItemCategory: { __typename?: 'InventoryItemCategory', id: number, name: string, businessUnitId?: number | null, createdAt: string, updatedAt: string } };

export type UpdateInventoryItemCategoryMutationVariables = Exact<{
  businessUnitId: Scalars['Int'];
  data: UpdateInventoryItemCategoryInput;
}>;


export type UpdateInventoryItemCategoryMutation = { __typename?: 'Mutation', updateInventoryItemCategory: { __typename?: 'InventoryItemCategory', id: number, name: string, businessUnitId?: number | null, createdAt: string, updatedAt: string } };

export type GetFranchisorBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetFranchisorBySlugQuery = { __typename?: 'Query', franchisorBySlug?: { __typename?: 'Franchisor', id: number, name: string } | null };

export type GetBusinessUnitBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetBusinessUnitBySlugQuery = { __typename?: 'Query', businessUnitBySlug?: { __typename?: 'BusinessUnit', id: number, name: string } | null };

export type GetAllInventoryItemsByBusinessUnitQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
  sector?: InputMaybe<Scalars['String']>;
}>;


export type GetAllInventoryItemsByBusinessUnitQuery = { __typename?: 'Query', inventoryItemsByBusinessUnit: Array<{ __typename?: 'InventoryItem', id: number, name: string, itemType: ItemType, sector: Sector, positionOnCountInventory: number, quantity: number, minimumQuantity: number, packageQuantity?: number | null, unitPrice: number, supplier?: { __typename?: 'Supplier', id: number, name: string } | null, unitOfMeasure: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string }, unitOfMeasureForPackage?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, inventoryTransactions: Array<{ __typename?: 'InventoryTransaction', id: number, quantity: number, observation?: string | null, type: TransactionType, createdAt: string, updatedAt: string, user: { __typename?: 'User', id: number, firstName: string } }>, category?: { __typename?: 'InventoryItemCategory', id: number, name: string } | null }> };

export type GetAllUnitsOfMeasureQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUnitsOfMeasureQuery = { __typename?: 'Query', unitsOfMeasure: Array<{ __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string, createdAt: string, updatedAt: string }> };

export type GetCalculationOfTotalStockValueQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type GetCalculationOfTotalStockValueQuery = { __typename?: 'Query', calculateTotalStockValue: number };

export type ItemsInUrgentQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type ItemsInUrgentQuery = { __typename?: 'Query', itemsInUrgent: number };

export type ItemsInOutOfStockQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type ItemsInOutOfStockQuery = { __typename?: 'Query', itemsInOutOfStock: number };

export type GetInventoryTransactionsByBusinessUnitQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type GetInventoryTransactionsByBusinessUnitQuery = { __typename?: 'Query', inventoryTransactionsByBusinessUnit: Array<{ __typename?: 'InventoryTransaction', id: number, inventoryItemId: number, quantity: number, type: TransactionType, createdAt: string }> };

export type GetAllSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSuppliersQuery = { __typename?: 'Query', suppliers: Array<{ __typename?: 'Supplier', id: number, name: string }> };

export type GetAllItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllItemCategoriesQuery = { __typename?: 'Query', inventoryItemCategories: Array<{ __typename?: 'InventoryItemCategory', id: number, name: string }> };

export type GetInventoryItemDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInventoryItemDetailsQuery = { __typename?: 'Query', inventoryItemDetails: { __typename?: 'InventoryItem', id: number, name: string, itemType: ItemType, sector: Sector, positionOnCountInventory: number, quantity: number, minimumQuantity: number, packageQuantity?: number | null, unitPrice: number, createdAt: string, updatedAt: string, unitOfMeasure: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string }, unitOfMeasureForPackage?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, supplier?: { __typename?: 'Supplier', id: number, name: string } | null, category?: { __typename?: 'InventoryItemCategory', id: number, name: string } | null, inventoryTransactions: Array<{ __typename?: 'InventoryTransaction', id: number, quantity: number, type: TransactionType, observation?: string | null, createdAt: string, user: { __typename?: 'User', id: number, firstName: string } }> } };

export type GetInventoryItemTransactionsQueryVariables = Exact<{
  inventoryItemId: Scalars['Int'];
}>;


export type GetInventoryItemTransactionsQuery = { __typename?: 'Query', inventoryTransactionsByInventoryItemId: Array<{ __typename?: 'InventoryTransaction', id: number, quantity: number, type: TransactionType, observation?: string | null, createdAt: string, user: { __typename?: 'User', id: number, firstName: string } }> };

export type RefreshTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenQuery = { __typename?: 'Query', refreshToken?: { __typename?: 'RefreshTokenResponse', token: string, refreshToken: string } | null };

export type GetSuppliersByBusinessUnitQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type GetSuppliersByBusinessUnitQuery = { __typename?: 'Query', suppliersByBusinessUnit: Array<{ __typename?: 'Supplier', id: number, name: string, contact?: string | null, email: string, phone?: string | null, address?: string | null, createdAt: string, updatedAt: string }> };

export type GetInventoryItemCategoriesByBusinessUnitQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type GetInventoryItemCategoriesByBusinessUnitQuery = { __typename?: 'Query', inventoryItemCategoriesByBusinessUnit: Array<{ __typename?: 'InventoryItemCategory', id: number, name: string, businessUnitId?: number | null, createdAt: string, updatedAt: string }> };

export type GetAllInventoryItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInventoryItemCategoriesQuery = { __typename?: 'Query', inventoryItemCategories: Array<{ __typename?: 'InventoryItemCategory', id: number, name: string, businessUnitId?: number | null, createdAt: string, updatedAt: string }> };

export type GetInventoryItemCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInventoryItemCategoryQuery = { __typename?: 'Query', inventoryItemCategory?: { __typename?: 'InventoryItemCategory', id: number, name: string, businessUnitId?: number | null, createdAt: string, updatedAt: string } | null };

export type GetInventoryItemsForEstoqueQueryVariables = Exact<{
  businessUnitId: Scalars['Int'];
}>;


export type GetInventoryItemsForEstoqueQuery = { __typename?: 'Query', inventoryItemsWithLastTransaction: Array<{ __typename?: 'InventoryItemWithLastTransaction', id: number, name: string, quantity: number, minimumQuantity: number, sector: Sector, unitPrice: number, supplier: { __typename?: 'Supplier', name: string }, unitOfMeasure: { __typename?: 'UnitOfMeasure', name: string }, lastTransaction?: { __typename?: 'InventoryTransaction', id: number, inventoryItemId: number, quantity: number, type: TransactionType, observation?: string | null, createdAt: string, updatedAt: string, user: { __typename?: 'User', id: number, firstName: string } } | null }> };

export type MeFragment = { __typename?: 'User', id: number, firstName: string, lastName: string, businessUnitId?: number | null, email: string, role: Role, businessUnit?: { __typename?: 'BusinessUnit', name: string, slug: string } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, firstName: string, lastName: string, businessUnitId?: number | null, email: string, role: Role, businessUnit?: { __typename?: 'BusinessUnit', name: string, slug: string } | null } | null };

export type GetSignedUrlForPutMutationVariables = Exact<{
  data: S3SignedUrlInput;
}>;


export type GetSignedUrlForPutMutation = { __typename?: 'Mutation', getSignedS3UrlForPut?: { __typename?: 'SignedResponse', url: string, uploadUrl: string } | null };

export type GetBulkSignedUrlForPutMutationVariables = Exact<{
  data: S3BulkSignedUrlInput;
}>;


export type GetBulkSignedUrlForPutMutation = { __typename?: 'Mutation', getBulkSignedS3UrlForPut?: Array<{ __typename?: 'SignedResponse', url: string, uploadUrl: string, key: string }> | null };

export const InventoryItemDocumentFragmentDoc = gql`
    fragment InventoryItemDocument on InventoryItem {
  id
  name
  itemType
  sector
  positionOnCountInventory
  quantity
  minimumQuantity
  packageQuantity
  unitPrice
  supplier {
    id
    name
  }
  unitOfMeasure {
    id
    name
    abbreviation
  }
  unitOfMeasureForPackage {
    id
    name
    abbreviation
  }
  inventoryTransactions {
    id
    quantity
    observation
    type
    user {
      id
      firstName
    }
    createdAt
    updatedAt
  }
  category {
    id
    name
  }
}
    `;
export const InventoryTransactionDocumentFragmentDoc = gql`
    fragment InventoryTransactionDocument on InventoryTransaction {
  id
  inventoryItemId
  quantity
  type
  observation
  user {
    id
    firstName
  }
  createdAt
  updatedAt
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on User {
  id
  firstName
  lastName
  businessUnitId
  businessUnit {
    name
    slug
  }
  email
  role
}
    `;
export const CreateInventoryItemDocument = gql`
    mutation CreateInventoryItem($data: CreateInventoryItemDto!) {
  createInventoryItem(data: $data) {
    id
    name
    itemType
    sector
    quantity
    unitPrice
    minimumQuantity
    positionOnCountInventory
    unitOfMeasureId
    packageQuantity
    unitOfMeasureForPackageId
    businessUnitId
    supplierId
    unitOfMeasure {
      id
      name
      abbreviation
    }
    unitOfMeasureForPackage {
      id
      name
      abbreviation
    }
    supplier {
      id
      name
    }
    inventoryTransactions {
      id
      quantity
      type
      observation
      user {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export function useCreateInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>(CreateInventoryItemDocument, options);
      }
export type CreateInventoryItemMutationHookResult = ReturnType<typeof useCreateInventoryItemMutation>;
export type CreateInventoryItemMutationResult = Apollo.MutationResult<CreateInventoryItemMutation>;
export type CreateInventoryItemMutationOptions = Apollo.BaseMutationOptions<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>;
export const UpdateInventoryItemDocument = gql`
    mutation UpdateInventoryItem($id: Int!, $data: UpdateInventoryItemDto!) {
  updateInventoryItem(id: $id, data: $data) {
    id
    name
    itemType
    sector
    quantity
    unitPrice
    minimumQuantity
    positionOnCountInventory
    unitOfMeasureId
    packageQuantity
    unitOfMeasureForPackageId
    businessUnitId
    supplierId
    unitOfMeasure {
      id
      name
      abbreviation
    }
    unitOfMeasureForPackage {
      id
      name
      abbreviation
    }
    supplier {
      id
      name
    }
    inventoryTransactions {
      id
      quantity
      type
      observation
      user {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export function useUpdateInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>(UpdateInventoryItemDocument, options);
      }
export type UpdateInventoryItemMutationHookResult = ReturnType<typeof useUpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationResult = Apollo.MutationResult<UpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    user {
      id
      firstName
      lastName
      email
      businessUnit {
        name
        slug
      }
    }
    token
    refreshToken
  }
}
    `;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data)
}
    `;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const AdjustInventoryItemsDocument = gql`
    mutation adjustInventoryItems($adjustments: [InventoryAdjustmentDto!]!) {
  adjustInventory(adjustments: $adjustments)
}
    `;
export function useAdjustInventoryItemsMutation(baseOptions?: Apollo.MutationHookOptions<AdjustInventoryItemsMutation, AdjustInventoryItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdjustInventoryItemsMutation, AdjustInventoryItemsMutationVariables>(AdjustInventoryItemsDocument, options);
      }
export type AdjustInventoryItemsMutationHookResult = ReturnType<typeof useAdjustInventoryItemsMutation>;
export type AdjustInventoryItemsMutationResult = Apollo.MutationResult<AdjustInventoryItemsMutation>;
export type AdjustInventoryItemsMutationOptions = Apollo.BaseMutationOptions<AdjustInventoryItemsMutation, AdjustInventoryItemsMutationVariables>;
export const UpdatePositionOrderDocument = gql`
    mutation UpdatePositionOrder($items: [UpdatePositionOrderItemInput!]!) {
  updatePositionOrder(items: $items) {
    id
    positionOnCountInventory
  }
}
    `;
export function useUpdatePositionOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePositionOrderMutation, UpdatePositionOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePositionOrderMutation, UpdatePositionOrderMutationVariables>(UpdatePositionOrderDocument, options);
      }
export type UpdatePositionOrderMutationHookResult = ReturnType<typeof useUpdatePositionOrderMutation>;
export type UpdatePositionOrderMutationResult = Apollo.MutationResult<UpdatePositionOrderMutation>;
export type UpdatePositionOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePositionOrderMutation, UpdatePositionOrderMutationVariables>;
export const DeleteInventoryItemDocument = gql`
    mutation DeleteInventoryItem($id: Int!) {
  removeInventoryItem(id: $id)
}
    `;
export function useDeleteInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>(DeleteInventoryItemDocument, options);
      }
export type DeleteInventoryItemMutationHookResult = ReturnType<typeof useDeleteInventoryItemMutation>;
export type DeleteInventoryItemMutationResult = Apollo.MutationResult<DeleteInventoryItemMutation>;
export type DeleteInventoryItemMutationOptions = Apollo.BaseMutationOptions<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const CreateSupplierDocument = gql`
    mutation CreateSupplier($data: CreateSupplierDto!) {
  createSupplier(data: $data) {
    id
    name
    contact
    email
    phone
    address
  }
}
    `;
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, options);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation UpdateSupplier($id: Int!, $data: UpdateSupplierDto!) {
  updateSupplier(id: $id, data: $data) {
    id
    name
    contact
    email
    phone
    address
  }
}
    `;
export function useUpdateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, options);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const CreateInventoryItemCategoryDocument = gql`
    mutation CreateInventoryItemCategory($data: CreateInventoryItemCategoryInput!) {
  createInventoryItemCategory(data: $data) {
    id
    name
    businessUnitId
    createdAt
    updatedAt
  }
}
    `;
export function useCreateInventoryItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryItemCategoryMutation, CreateInventoryItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryItemCategoryMutation, CreateInventoryItemCategoryMutationVariables>(CreateInventoryItemCategoryDocument, options);
      }
export type CreateInventoryItemCategoryMutationHookResult = ReturnType<typeof useCreateInventoryItemCategoryMutation>;
export type CreateInventoryItemCategoryMutationResult = Apollo.MutationResult<CreateInventoryItemCategoryMutation>;
export type CreateInventoryItemCategoryMutationOptions = Apollo.BaseMutationOptions<CreateInventoryItemCategoryMutation, CreateInventoryItemCategoryMutationVariables>;
export const UpdateInventoryItemCategoryDocument = gql`
    mutation UpdateInventoryItemCategory($businessUnitId: Int!, $data: UpdateInventoryItemCategoryInput!) {
  updateInventoryItemCategory(businessUnitId: $businessUnitId, data: $data) {
    id
    name
    businessUnitId
    createdAt
    updatedAt
  }
}
    `;
export function useUpdateInventoryItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryItemCategoryMutation, UpdateInventoryItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryItemCategoryMutation, UpdateInventoryItemCategoryMutationVariables>(UpdateInventoryItemCategoryDocument, options);
      }
export type UpdateInventoryItemCategoryMutationHookResult = ReturnType<typeof useUpdateInventoryItemCategoryMutation>;
export type UpdateInventoryItemCategoryMutationResult = Apollo.MutationResult<UpdateInventoryItemCategoryMutation>;
export type UpdateInventoryItemCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryItemCategoryMutation, UpdateInventoryItemCategoryMutationVariables>;
export const GetFranchisorBySlugDocument = gql`
    query GetFranchisorBySlug($slug: String!) {
  franchisorBySlug(slug: $slug) {
    id
    name
  }
}
    `;
export function useGetFranchisorBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>(GetFranchisorBySlugDocument, options);
      }
export function useGetFranchisorBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>(GetFranchisorBySlugDocument, options);
        }
export type GetFranchisorBySlugQueryHookResult = ReturnType<typeof useGetFranchisorBySlugQuery>;
export type GetFranchisorBySlugLazyQueryHookResult = ReturnType<typeof useGetFranchisorBySlugLazyQuery>;
export type GetFranchisorBySlugQueryResult = Apollo.QueryResult<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>;
export const GetBusinessUnitBySlugDocument = gql`
    query GetBusinessUnitBySlug($slug: String!) {
  businessUnitBySlug(slug: $slug) {
    id
    name
  }
}
    `;
export function useGetBusinessUnitBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>(GetBusinessUnitBySlugDocument, options);
      }
export function useGetBusinessUnitBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>(GetBusinessUnitBySlugDocument, options);
        }
export type GetBusinessUnitBySlugQueryHookResult = ReturnType<typeof useGetBusinessUnitBySlugQuery>;
export type GetBusinessUnitBySlugLazyQueryHookResult = ReturnType<typeof useGetBusinessUnitBySlugLazyQuery>;
export type GetBusinessUnitBySlugQueryResult = Apollo.QueryResult<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>;
export const GetAllInventoryItemsByBusinessUnitDocument = gql`
    query GetAllInventoryItemsByBusinessUnit($businessUnitId: Int!, $sector: String) {
  inventoryItemsByBusinessUnit(businessUnitId: $businessUnitId, sector: $sector) {
    ...InventoryItemDocument
  }
}
    ${InventoryItemDocumentFragmentDoc}`;
export function useGetAllInventoryItemsByBusinessUnitQuery(baseOptions: Apollo.QueryHookOptions<GetAllInventoryItemsByBusinessUnitQuery, GetAllInventoryItemsByBusinessUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInventoryItemsByBusinessUnitQuery, GetAllInventoryItemsByBusinessUnitQueryVariables>(GetAllInventoryItemsByBusinessUnitDocument, options);
      }
export function useGetAllInventoryItemsByBusinessUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInventoryItemsByBusinessUnitQuery, GetAllInventoryItemsByBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInventoryItemsByBusinessUnitQuery, GetAllInventoryItemsByBusinessUnitQueryVariables>(GetAllInventoryItemsByBusinessUnitDocument, options);
        }
export type GetAllInventoryItemsByBusinessUnitQueryHookResult = ReturnType<typeof useGetAllInventoryItemsByBusinessUnitQuery>;
export type GetAllInventoryItemsByBusinessUnitLazyQueryHookResult = ReturnType<typeof useGetAllInventoryItemsByBusinessUnitLazyQuery>;
export type GetAllInventoryItemsByBusinessUnitQueryResult = Apollo.QueryResult<GetAllInventoryItemsByBusinessUnitQuery, GetAllInventoryItemsByBusinessUnitQueryVariables>;
export const GetAllUnitsOfMeasureDocument = gql`
    query GetAllUnitsOfMeasure {
  unitsOfMeasure {
    id
    name
    abbreviation
    createdAt
    updatedAt
  }
}
    `;
export function useGetAllUnitsOfMeasureQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUnitsOfMeasureQuery, GetAllUnitsOfMeasureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUnitsOfMeasureQuery, GetAllUnitsOfMeasureQueryVariables>(GetAllUnitsOfMeasureDocument, options);
      }
export function useGetAllUnitsOfMeasureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUnitsOfMeasureQuery, GetAllUnitsOfMeasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUnitsOfMeasureQuery, GetAllUnitsOfMeasureQueryVariables>(GetAllUnitsOfMeasureDocument, options);
        }
export type GetAllUnitsOfMeasureQueryHookResult = ReturnType<typeof useGetAllUnitsOfMeasureQuery>;
export type GetAllUnitsOfMeasureLazyQueryHookResult = ReturnType<typeof useGetAllUnitsOfMeasureLazyQuery>;
export type GetAllUnitsOfMeasureQueryResult = Apollo.QueryResult<GetAllUnitsOfMeasureQuery, GetAllUnitsOfMeasureQueryVariables>;
export const GetCalculationOfTotalStockValueDocument = gql`
    query GetCalculationOfTotalStockValue($businessUnitId: Int!) {
  calculateTotalStockValue(businessUnitId: $businessUnitId)
}
    `;
export function useGetCalculationOfTotalStockValueQuery(baseOptions: Apollo.QueryHookOptions<GetCalculationOfTotalStockValueQuery, GetCalculationOfTotalStockValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalculationOfTotalStockValueQuery, GetCalculationOfTotalStockValueQueryVariables>(GetCalculationOfTotalStockValueDocument, options);
      }
export function useGetCalculationOfTotalStockValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalculationOfTotalStockValueQuery, GetCalculationOfTotalStockValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalculationOfTotalStockValueQuery, GetCalculationOfTotalStockValueQueryVariables>(GetCalculationOfTotalStockValueDocument, options);
        }
export type GetCalculationOfTotalStockValueQueryHookResult = ReturnType<typeof useGetCalculationOfTotalStockValueQuery>;
export type GetCalculationOfTotalStockValueLazyQueryHookResult = ReturnType<typeof useGetCalculationOfTotalStockValueLazyQuery>;
export type GetCalculationOfTotalStockValueQueryResult = Apollo.QueryResult<GetCalculationOfTotalStockValueQuery, GetCalculationOfTotalStockValueQueryVariables>;
export const ItemsInUrgentDocument = gql`
    query ItemsInUrgent($businessUnitId: Int!) {
  itemsInUrgent(businessUnitId: $businessUnitId)
}
    `;
export function useItemsInUrgentQuery(baseOptions: Apollo.QueryHookOptions<ItemsInUrgentQuery, ItemsInUrgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemsInUrgentQuery, ItemsInUrgentQueryVariables>(ItemsInUrgentDocument, options);
      }
export function useItemsInUrgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsInUrgentQuery, ItemsInUrgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemsInUrgentQuery, ItemsInUrgentQueryVariables>(ItemsInUrgentDocument, options);
        }
export type ItemsInUrgentQueryHookResult = ReturnType<typeof useItemsInUrgentQuery>;
export type ItemsInUrgentLazyQueryHookResult = ReturnType<typeof useItemsInUrgentLazyQuery>;
export type ItemsInUrgentQueryResult = Apollo.QueryResult<ItemsInUrgentQuery, ItemsInUrgentQueryVariables>;
export const ItemsInOutOfStockDocument = gql`
    query ItemsInOutOfStock($businessUnitId: Int!) {
  itemsInOutOfStock(businessUnitId: $businessUnitId)
}
    `;
export function useItemsInOutOfStockQuery(baseOptions: Apollo.QueryHookOptions<ItemsInOutOfStockQuery, ItemsInOutOfStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemsInOutOfStockQuery, ItemsInOutOfStockQueryVariables>(ItemsInOutOfStockDocument, options);
      }
export function useItemsInOutOfStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsInOutOfStockQuery, ItemsInOutOfStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemsInOutOfStockQuery, ItemsInOutOfStockQueryVariables>(ItemsInOutOfStockDocument, options);
        }
export type ItemsInOutOfStockQueryHookResult = ReturnType<typeof useItemsInOutOfStockQuery>;
export type ItemsInOutOfStockLazyQueryHookResult = ReturnType<typeof useItemsInOutOfStockLazyQuery>;
export type ItemsInOutOfStockQueryResult = Apollo.QueryResult<ItemsInOutOfStockQuery, ItemsInOutOfStockQueryVariables>;
export const GetInventoryTransactionsByBusinessUnitDocument = gql`
    query GetInventoryTransactionsByBusinessUnit($businessUnitId: Int!) {
  inventoryTransactionsByBusinessUnit(businessUnitId: $businessUnitId) {
    id
    inventoryItemId
    quantity
    type
    createdAt
  }
}
    `;
export function useGetInventoryTransactionsByBusinessUnitQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryTransactionsByBusinessUnitQuery, GetInventoryTransactionsByBusinessUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryTransactionsByBusinessUnitQuery, GetInventoryTransactionsByBusinessUnitQueryVariables>(GetInventoryTransactionsByBusinessUnitDocument, options);
      }
export function useGetInventoryTransactionsByBusinessUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryTransactionsByBusinessUnitQuery, GetInventoryTransactionsByBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryTransactionsByBusinessUnitQuery, GetInventoryTransactionsByBusinessUnitQueryVariables>(GetInventoryTransactionsByBusinessUnitDocument, options);
        }
export type GetInventoryTransactionsByBusinessUnitQueryHookResult = ReturnType<typeof useGetInventoryTransactionsByBusinessUnitQuery>;
export type GetInventoryTransactionsByBusinessUnitLazyQueryHookResult = ReturnType<typeof useGetInventoryTransactionsByBusinessUnitLazyQuery>;
export type GetInventoryTransactionsByBusinessUnitQueryResult = Apollo.QueryResult<GetInventoryTransactionsByBusinessUnitQuery, GetInventoryTransactionsByBusinessUnitQueryVariables>;
export const GetAllSuppliersDocument = gql`
    query GetAllSuppliers {
  suppliers {
    id
    name
  }
}
    `;
export function useGetAllSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>(GetAllSuppliersDocument, options);
      }
export function useGetAllSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>(GetAllSuppliersDocument, options);
        }
export type GetAllSuppliersQueryHookResult = ReturnType<typeof useGetAllSuppliersQuery>;
export type GetAllSuppliersLazyQueryHookResult = ReturnType<typeof useGetAllSuppliersLazyQuery>;
export type GetAllSuppliersQueryResult = Apollo.QueryResult<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>;
export const GetAllItemCategoriesDocument = gql`
    query GetAllItemCategories {
  inventoryItemCategories {
    id
    name
  }
}
    `;
export function useGetAllItemCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>(GetAllItemCategoriesDocument, options);
      }
export function useGetAllItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>(GetAllItemCategoriesDocument, options);
        }
export type GetAllItemCategoriesQueryHookResult = ReturnType<typeof useGetAllItemCategoriesQuery>;
export type GetAllItemCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllItemCategoriesLazyQuery>;
export type GetAllItemCategoriesQueryResult = Apollo.QueryResult<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>;
export const GetInventoryItemDetailsDocument = gql`
    query GetInventoryItemDetails($id: Int!) {
  inventoryItemDetails(id: $id) {
    id
    name
    itemType
    sector
    positionOnCountInventory
    quantity
    minimumQuantity
    packageQuantity
    unitPrice
    unitOfMeasure {
      id
      name
      abbreviation
    }
    unitOfMeasureForPackage {
      id
      name
      abbreviation
    }
    supplier {
      id
      name
    }
    category {
      id
      name
    }
    inventoryTransactions {
      id
      quantity
      type
      observation
      user {
        id
        firstName
      }
      createdAt
    }
    createdAt
    updatedAt
  }
}
    `;
export function useGetInventoryItemDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryItemDetailsQuery, GetInventoryItemDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryItemDetailsQuery, GetInventoryItemDetailsQueryVariables>(GetInventoryItemDetailsDocument, options);
      }
export function useGetInventoryItemDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryItemDetailsQuery, GetInventoryItemDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryItemDetailsQuery, GetInventoryItemDetailsQueryVariables>(GetInventoryItemDetailsDocument, options);
        }
export type GetInventoryItemDetailsQueryHookResult = ReturnType<typeof useGetInventoryItemDetailsQuery>;
export type GetInventoryItemDetailsLazyQueryHookResult = ReturnType<typeof useGetInventoryItemDetailsLazyQuery>;
export type GetInventoryItemDetailsQueryResult = Apollo.QueryResult<GetInventoryItemDetailsQuery, GetInventoryItemDetailsQueryVariables>;
export const GetInventoryItemTransactionsDocument = gql`
    query GetInventoryItemTransactions($inventoryItemId: Int!) {
  inventoryTransactionsByInventoryItemId(inventoryItemId: $inventoryItemId) {
    id
    quantity
    type
    observation
    user {
      id
      firstName
    }
    createdAt
  }
}
    `;
export function useGetInventoryItemTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryItemTransactionsQuery, GetInventoryItemTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryItemTransactionsQuery, GetInventoryItemTransactionsQueryVariables>(GetInventoryItemTransactionsDocument, options);
      }
export function useGetInventoryItemTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryItemTransactionsQuery, GetInventoryItemTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryItemTransactionsQuery, GetInventoryItemTransactionsQueryVariables>(GetInventoryItemTransactionsDocument, options);
        }
export type GetInventoryItemTransactionsQueryHookResult = ReturnType<typeof useGetInventoryItemTransactionsQuery>;
export type GetInventoryItemTransactionsLazyQueryHookResult = ReturnType<typeof useGetInventoryItemTransactionsLazyQuery>;
export type GetInventoryItemTransactionsQueryResult = Apollo.QueryResult<GetInventoryItemTransactionsQuery, GetInventoryItemTransactionsQueryVariables>;
export const RefreshTokenDocument = gql`
    query RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
  }
}
    `;
export function useRefreshTokenQuery(baseOptions: Apollo.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
      }
export function useRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const GetSuppliersByBusinessUnitDocument = gql`
    query GetSuppliersByBusinessUnit($businessUnitId: Int!) {
  suppliersByBusinessUnit(businessUnitId: $businessUnitId) {
    id
    name
    contact
    email
    phone
    address
    createdAt
    updatedAt
  }
}
    `;
export function useGetSuppliersByBusinessUnitQuery(baseOptions: Apollo.QueryHookOptions<GetSuppliersByBusinessUnitQuery, GetSuppliersByBusinessUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuppliersByBusinessUnitQuery, GetSuppliersByBusinessUnitQueryVariables>(GetSuppliersByBusinessUnitDocument, options);
      }
export function useGetSuppliersByBusinessUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersByBusinessUnitQuery, GetSuppliersByBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuppliersByBusinessUnitQuery, GetSuppliersByBusinessUnitQueryVariables>(GetSuppliersByBusinessUnitDocument, options);
        }
export type GetSuppliersByBusinessUnitQueryHookResult = ReturnType<typeof useGetSuppliersByBusinessUnitQuery>;
export type GetSuppliersByBusinessUnitLazyQueryHookResult = ReturnType<typeof useGetSuppliersByBusinessUnitLazyQuery>;
export type GetSuppliersByBusinessUnitQueryResult = Apollo.QueryResult<GetSuppliersByBusinessUnitQuery, GetSuppliersByBusinessUnitQueryVariables>;
export const GetInventoryItemCategoriesByBusinessUnitDocument = gql`
    query GetInventoryItemCategoriesByBusinessUnit($businessUnitId: Int!) {
  inventoryItemCategoriesByBusinessUnit(businessUnitId: $businessUnitId) {
    id
    name
    businessUnitId
    createdAt
    updatedAt
  }
}
    `;
export function useGetInventoryItemCategoriesByBusinessUnitQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryItemCategoriesByBusinessUnitQuery, GetInventoryItemCategoriesByBusinessUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryItemCategoriesByBusinessUnitQuery, GetInventoryItemCategoriesByBusinessUnitQueryVariables>(GetInventoryItemCategoriesByBusinessUnitDocument, options);
      }
export function useGetInventoryItemCategoriesByBusinessUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryItemCategoriesByBusinessUnitQuery, GetInventoryItemCategoriesByBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryItemCategoriesByBusinessUnitQuery, GetInventoryItemCategoriesByBusinessUnitQueryVariables>(GetInventoryItemCategoriesByBusinessUnitDocument, options);
        }
export type GetInventoryItemCategoriesByBusinessUnitQueryHookResult = ReturnType<typeof useGetInventoryItemCategoriesByBusinessUnitQuery>;
export type GetInventoryItemCategoriesByBusinessUnitLazyQueryHookResult = ReturnType<typeof useGetInventoryItemCategoriesByBusinessUnitLazyQuery>;
export type GetInventoryItemCategoriesByBusinessUnitQueryResult = Apollo.QueryResult<GetInventoryItemCategoriesByBusinessUnitQuery, GetInventoryItemCategoriesByBusinessUnitQueryVariables>;
export const GetAllInventoryItemCategoriesDocument = gql`
    query GetAllInventoryItemCategories {
  inventoryItemCategories {
    id
    name
    businessUnitId
    createdAt
    updatedAt
  }
}
    `;
export function useGetAllInventoryItemCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInventoryItemCategoriesQuery, GetAllInventoryItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInventoryItemCategoriesQuery, GetAllInventoryItemCategoriesQueryVariables>(GetAllInventoryItemCategoriesDocument, options);
      }
export function useGetAllInventoryItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInventoryItemCategoriesQuery, GetAllInventoryItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInventoryItemCategoriesQuery, GetAllInventoryItemCategoriesQueryVariables>(GetAllInventoryItemCategoriesDocument, options);
        }
export type GetAllInventoryItemCategoriesQueryHookResult = ReturnType<typeof useGetAllInventoryItemCategoriesQuery>;
export type GetAllInventoryItemCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllInventoryItemCategoriesLazyQuery>;
export type GetAllInventoryItemCategoriesQueryResult = Apollo.QueryResult<GetAllInventoryItemCategoriesQuery, GetAllInventoryItemCategoriesQueryVariables>;
export const GetInventoryItemCategoryDocument = gql`
    query GetInventoryItemCategory($id: Int!) {
  inventoryItemCategory(id: $id) {
    id
    name
    businessUnitId
    createdAt
    updatedAt
  }
}
    `;
export function useGetInventoryItemCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryItemCategoryQuery, GetInventoryItemCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryItemCategoryQuery, GetInventoryItemCategoryQueryVariables>(GetInventoryItemCategoryDocument, options);
      }
export function useGetInventoryItemCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryItemCategoryQuery, GetInventoryItemCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryItemCategoryQuery, GetInventoryItemCategoryQueryVariables>(GetInventoryItemCategoryDocument, options);
        }
export type GetInventoryItemCategoryQueryHookResult = ReturnType<typeof useGetInventoryItemCategoryQuery>;
export type GetInventoryItemCategoryLazyQueryHookResult = ReturnType<typeof useGetInventoryItemCategoryLazyQuery>;
export type GetInventoryItemCategoryQueryResult = Apollo.QueryResult<GetInventoryItemCategoryQuery, GetInventoryItemCategoryQueryVariables>;
export const GetInventoryItemsForEstoqueDocument = gql`
    query GetInventoryItemsForEstoque($businessUnitId: Int!) {
  inventoryItemsWithLastTransaction(businessUnitId: $businessUnitId) {
    id
    name
    quantity
    minimumQuantity
    sector
    unitPrice
    supplier {
      name
    }
    unitOfMeasure {
      name
    }
    lastTransaction {
      ...InventoryTransactionDocument
    }
  }
}
    ${InventoryTransactionDocumentFragmentDoc}`;
export function useGetInventoryItemsForEstoqueQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryItemsForEstoqueQuery, GetInventoryItemsForEstoqueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryItemsForEstoqueQuery, GetInventoryItemsForEstoqueQueryVariables>(GetInventoryItemsForEstoqueDocument, options);
      }
export function useGetInventoryItemsForEstoqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryItemsForEstoqueQuery, GetInventoryItemsForEstoqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryItemsForEstoqueQuery, GetInventoryItemsForEstoqueQueryVariables>(GetInventoryItemsForEstoqueDocument, options);
        }
export type GetInventoryItemsForEstoqueQueryHookResult = ReturnType<typeof useGetInventoryItemsForEstoqueQuery>;
export type GetInventoryItemsForEstoqueLazyQueryHookResult = ReturnType<typeof useGetInventoryItemsForEstoqueLazyQuery>;
export type GetInventoryItemsForEstoqueQueryResult = Apollo.QueryResult<GetInventoryItemsForEstoqueQuery, GetInventoryItemsForEstoqueQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetSignedUrlForPutDocument = gql`
    mutation GetSignedUrlForPut($data: S3SignedUrlInput!) {
  getSignedS3UrlForPut(data: $data) {
    url
    uploadUrl
  }
}
    `;
export function useGetSignedUrlForPutMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>(GetSignedUrlForPutDocument, options);
      }
export type GetSignedUrlForPutMutationHookResult = ReturnType<typeof useGetSignedUrlForPutMutation>;
export type GetSignedUrlForPutMutationResult = Apollo.MutationResult<GetSignedUrlForPutMutation>;
export type GetSignedUrlForPutMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>;
export const GetBulkSignedUrlForPutDocument = gql`
    mutation GetBulkSignedUrlForPut($data: S3BulkSignedUrlInput!) {
  getBulkSignedS3UrlForPut(data: $data) {
    url
    uploadUrl
    key
  }
}
    `;
export function useGetBulkSignedUrlForPutMutation(baseOptions?: Apollo.MutationHookOptions<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>(GetBulkSignedUrlForPutDocument, options);
      }
export type GetBulkSignedUrlForPutMutationHookResult = ReturnType<typeof useGetBulkSignedUrlForPutMutation>;
export type GetBulkSignedUrlForPutMutationResult = Apollo.MutationResult<GetBulkSignedUrlForPutMutation>;
export type GetBulkSignedUrlForPutMutationOptions = Apollo.BaseMutationOptions<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>;